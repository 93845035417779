.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 4px;
  height: 48px;
  padding: 4px;
}

.alert[data-alert-type="warning"] {
  background-color: #fff6e6;
}

.alert[data-alert-type="error"] {
  background-color: #ffe6e6;
}

.alert[data-alert-type="info"] {
  background-color: #e6ebff;
}

.alert[data-alert-type="success"] {
  background-color: #e6ffe6;
}

[data-color-scheme="dark"] .alert[data-alert-type="warning"] {
  background-color: #805d20;
}

[data-color-scheme="dark"] .alert[data-alert-type="error"] {
  background-color: #802020;
}

[data-color-scheme="dark"] .alert[data-alert-type="info"] {
  background-color: #203380;
}

[data-color-scheme="dark"] .alert[data-alert-type="success"] {
  background-color: #208020;
}

.alert-icon-wrapper {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  height: 18px;
  width: 18px;
  user-select: none;
  cursor: pointer;
}

.alert-icon[data-alert-icon-type="warning"] {
  color: #e69819
}

.alert-icon[data-alert-icon-type="error"] {
  color: #d80d0d
}

.alert-icon[data-alert-icon-type="info"] {
  color: #507aff
}

.alert-icon[data-alert-icon-type="success"] {
  color: #0bc10b
}

.quiz {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 4px;
  height: 48px;
  padding: 4px;
}

.quiz-icon-wrapper {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  height: 18px;
  width: 18px;
  user-select: none;
}

.inline-content {
  flex-grow: 1;
}
