
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-xxs {
  font-size: 10px;
}

.text-xxxs {
  font-size: 8px;
}

.bg-primary {
  background-color: #1C2541;
}

.border-primary {
  border-style: solid;
  border-color: #1C2541;
}

.text-primary {
  color: #1C2541;
}

/* .primary {
  background-color: #0971f1;
} */

.total-metrics-padding {
  padding-top: 14px;
}

@media only screen and (max-width: 1200px) {
  .text-xxs {
    font-size: 8px;
  }

  .text-xxxs {
    font-size: 6px;
  }
}

.daily-metrics-width {
  width: 70px;
}

.loaded-from-flag {
  position: absolute;
  top: 6px;
  left: 450px;
}

.text-xxs {
  font-size: 10px;
}
