.str-chat {
  --str-chat__primary-color: #6d28d9;
  --str-chat__active-primary-color: #4c1d95;
  --str-chat__surface-color: #f1f5f9;
  --str-chat__secondary-surface-color: #f1f5f9;
  /* --str-chat__primary-surface-color: #e0f2f1;
  --str-chat__primary-surface-color-low-emphasis: #edf7f7; */
  --str-chat__border-radius-circle: 50px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 25%;
  min-width: 290px;
}

.channel-list {
  height: 50%;
  overflow-y: auto;
}

.dm-list {
  height: 50%;
  overflow-y: auto;
}


.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.str-chat__main-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.str-chat__message-list {
  flex: 1;
  overflow-y: auto;
}

.str-chat__input-flat {
  margin-top: auto;
}

.str-chat__channel-header {
  border-bottom: 1px solid #e2e8f0;
  padding: 15px 20px
}

.str-chat__channel-search .str-chat__channel-search-bar {
  height: 80px
}

.str-chat__edit-message-form .str-chat__edit-message-form-options .str-chat__edit-message-send {
  margin-left: 20px
}
